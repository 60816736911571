import RecogImg1 from "../Homepage/Recognition/UN.jpg"
import RecogImg2 from "../Homepage/Recognition/SME.png"
import RecogImg4 from "../Homepage/Recognition/PromisingPunjab.png"
import RecogImg5 from "../Homepage/Recognition/BOTY.png"
import RecogImg6 from "../Homepage/Recognition/SOTY.png"
import RecogImg7 from "../Homepage/Recognition/Flame award.png"
import RecogImg8 from "../Homepage/Recognition/Entrepreneur.png";
// import RecogImg9 from "../Homepage/Recognition/IDBI.png";
import RecogImg10 from "../Homepage/Recognition/degree.png";
// import RecogImg11 from "../Homepage/Recognition/UpcomingSOTY.png";
import  RecogImg12 from "../Homepage/Recognition/who.png";
// import RecogImg13 from "../Homepage/Recognition/kabaddi.png";
import  RecogImg14 from "../Homepage/Recognition/PMI.png";
import RecogImg15 from "../Homepage/Recognition/SP.png";
import RecogImg16 from "../Homepage/Recognition/retailer.png";

const RecogData = [
    
    {
        image: RecogImg1,
        content: "Pumpkart: The first Indian startup to represent India at the United Nations.",
    },
    {
        image: RecogImg14,
        content: "Recognized by The Prime Minister of India in 2015."
    },
    {
        image: RecogImg15,
        content: "Recognized by Google CEO Sunder Pichai in front of Prime Minister of India."
    },
    {
        image: RecogImg16,
        content: "Featured on the front page of the Retailer Magazine."
    },
    {
        image: RecogImg12,
        content: "Featured in Who's Who Global Magazine as a Global Disruptor."
    },
    {
        image: RecogImg7,
        content: "Flame Leadership Award"
    },
    {
        image: RecogImg5,
        content: "Best young brand of the year from the hands of finance minister, Punjab"
    },
    {
        image: RecogImg2,
        content: "Best SME from Northern Region by Google India",
    },
    {
        image: RecogImg8,
        content: "Entrepreneur of The Year Award"
    },
    {
        image: RecogImg6,
        content: "Startup of the year"
    },
    // {
    //     image:  RecogImg11,
    //     content: "Upcoming Startup of The Year Award"

    // },
    {
        image: RecogImg4,
        content: "Most Promising Indian by Promising India Society"
    },
    // {
    //     image: RecogImg9,
    //     content: "Best SME from IDBI BANK"
    // },
    {
        image: RecogImg10,
        content: "Honoury Doctor Degree by Sorbonne University, France"
    },
    // {
    //     image: RecogImg13,
    //     content: "Chief Guest at Indian Pro Kabaddi League"

    // }
]

export default RecogData;