import React, {useEffect} from 'react';
import "../Stylesheets/Homepage.css";
import { motion } from 'framer-motion';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const BacktoTop = () => {

  const [visible, setVisible] = React.useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 600) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const variants = {
    visible: {opacity: 1},
    hidden: {opacity: 0}
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div className='back-to-top'>
       { visible && <motion.div className='top-button' variants={variants} 
                initial="hidden" 
                whileInView="visible" 
                transition={{duration: 0.4}} 
                viewport={{once: true, amount: 0.5}}>
            <a href='#top' title='Back to Top'>
                <KeyboardArrowUpIcon sx={{color: "#133E87"}} />
            </a>
        </motion.div>}
    </div>
  )
}

export default BacktoTop