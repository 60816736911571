import React from 'react'
import DrawerAppBar from './Navbar'
import '../Stylesheets/Homepage.css'
import LandingIntro from './LandingIntro';
import HomeAbout from './HomeSection2';
import WorkSection from './HomeSection3';
import RecognitionSection from './HomeSection4';
import HomePress from './HomeSection5';
import HomeSpeaker from './HomeSection6';
import HomeRecordings from './HomeSection7';
import Footer from './Footer';
import BacktoTop from './BacktoTop';


const HomePage = () => {
  return (
    <div>
        <div id='top'>
        <DrawerAppBar />
        <LandingIntro />
        </div>
        <HomeAbout />
        <WorkSection />
        <RecognitionSection />
        <HomePress />
        <HomeSpeaker />
        <HomeRecordings />
        <Footer />
        <BacktoTop />
    </div>
  )
}

export default HomePage