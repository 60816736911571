import React from 'react'
import "../Stylesheets/Homepage.css";
import {motion} from "framer-motion";
import Subheading from './SubHeading';
import ParaContent from './paraContent';
import MotionButton from './MotionButton';
import AboutImg from '../Resources/Homepage/HomeAbout.jpg';

const content = ["As the founder of Mr. Proptek, an AI-powered platform revolutionizing the real estate buying process, I am dedicated to shaping the future of property transactions. With over 25 years of business experience, my focus is on driving disruptive innovation in the proptech space. Mr. Proptek, set to launch in Dubai in January 2025, is poised to become a global brand, leveraging cutting-edge artificial intelligence to streamline property buying and selling, enhance decision-making, and predict market trends.", <br />, "Previously, I founded Pumpkart, which transformed the water pumps industry and garnered recognition from Google’s CEO Sundar Pichai in front of India’s Prime Minister. A global thought leader, I have spoken at the United Nations as the first Indian startup founder to do so, and have earned accolades such as the Leadership Award from RMAI and Amity University, as well as recognition from TIMES as a Top 30 leader. I hold a B.Tech in Chemical Engineering from Bangalore University, an MBA from IMT Ghaziabad, and an honorary doctorate from Sorbonne University, France."]

const HomeAbout = () => {

  return (
    <div id='about-section'>
        <div className='about-main about-mobile'>
            <div className='about-left profile-image flex-custom center-flex'>
                <motion.img initial={{opacity:0, x:-80}} animate={{opacity:1, x:0}} transition={{duration: 0.6, ease: "easeInOut"}} src={AboutImg} alt='about-img'></motion.img>
            </div>
            <div className='about-right body-color-blue flex-custom center-flex'>
                <div className='about-right-top'>
                    <Subheading content="About Me" />
                    <ParaContent className="quicksand about-text" content={content} />
                    <MotionButton location="/about" content='Know More' />
                </div>            
            </div>
        </div>
    </div>
  )
}

export default HomeAbout