import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import "../Stylesheets/Navbar.css";
import { HashLink } from "react-router-hash-link";

const drawerWidth = 240;

function handleNavigation() {
  window.location.href = "/gallery";
}

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <a href="/">
          <span className="caramel nav-brand">KS</span>
        </a>
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding sx={{ justifyContent: "center" }}>
          <div className="flex-custom flex-column center-flex">
            <ListItemButton sx={{ textAlign: "center" }}>
              <HashLink smooth to="/#about-section">
                About
              </HashLink>
            </ListItemButton>
            <ListItemButton sx={{ textAlign: "center" }}>
              <HashLink smooth to="/#recog-section">
                Recognitions
              </HashLink>
            </ListItemButton>
            <ListItemButton sx={{ textAlign: "center" }}>
              <HashLink smooth to="/#press">
                Press
              </HashLink>
            </ListItemButton>
            <ListItemButton sx={{ textAlign: "center" }}>
              <HashLink smooth to="/#recordings">
                Recordings
              </HashLink>
            </ListItemButton>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={handleNavigation}
            >
              <ListItemText primary="Gallery" />
            </ListItemButton>
            <ListItemButton sx={{ textAlign: "center" }} href="#footer">
              <ListItemText primary="Contact" />
            </ListItemButton>
          </div>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" className="navbar-custom" position="sticky">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <a href="/">
              <span className="caramel nav-brand">KS</span>
            </a>
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button className="nav-button">
              <HashLink smooth to="/#about-section">
                About
              </HashLink>
            </Button>
            <Button className="nav-button">
              <HashLink smooth to="/#recog-section">
                Recognitions
              </HashLink>
            </Button>
            <Button className="nav-button">
              <HashLink smooth to="/#press">
                Press
              </HashLink>
            </Button>
            <Button className="nav-button">
              <HashLink smooth to="/#recordings">
                Recordings
              </HashLink>
            </Button>
            <Button className="nav-button" onClick={handleNavigation}>
              Gallery
            </Button>
            <Button className="nav-button" href="#footer">
              Contact
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

export default DrawerAppBar;
